/* eslint-disable */
import '../scss/style.scss';

import $ from 'jquery';
// import './lib/sample';
import './lib/spNav';
import './lib/splide';
import './lib/scroll-animation';

$(function() {
});

window.onload = function () {
};
