document.addEventListener( 'DOMContentLoaded', function() {

  if (document.querySelector('.p-top .splide')) {
    const splide = new Splide('.p-top .splide', {
      type: 'loop',
      autoplay: 'play',
      interval: 5000,
      speed: 3000,
      perPage: 1,
      breakpoints: {
        600: {
          gap: 0,
        },
      },
      gap: '4.28vw',
      focus  : 'center',
      pagination: false
    })
    splide.mount();
  }

})