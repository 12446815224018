document.addEventListener( 'DOMContentLoaded', function() {
  
  const targetElements = document.querySelectorAll('.js-animation');
  
  const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const el = entry.target;
        const halfHeight = el.clientHeight * 0.5;
        const preferredHeight = Math.min(halfHeight, 100);
        const getElementDistance = el.getBoundingClientRect().top + preferredHeight;
        if (window.innerHeight > getElementDistance) {
          el.classList.add('show');
        }
        intersectionObserver.unobserve(el);
      }
    });
  }, { threshold: 0.5 }); // ここで交差のしきい値を設定しています
  
  // 各要素に対して監視を開始
  targetElements.forEach(el => {
    intersectionObserver.observe(el);
  });
})

